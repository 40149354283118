.App {
    background-color: #ffffff;
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App div {
    display: flex;
    flex-direction: column;
}

@supports (font-variation-settings: 'wdth' 200) {
    @font-face {
        font-family: Manrope;
        src: url('./fonts/Manrope-VariableFont_wght.ttf');
        font-weight: normal;
        font-style: normal;
    }

    .App {
        font-family: Manrope;
        font-variation-settings: 'wdth' 900, 'wght' 200;
    }
}
