.HomePage {
    /* https://www.pexels.com/photo/light-landscape-nature-red-33041/ */
    /* background: url('./bg_canyon.jpg'); */
    /* https://www.pexels.com/photo/blue-and-green-sky-and-mountain-3617500/ */
    /* background: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url('./bg_mountain.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    flex: 1;
    color: white;
}

.HomePage header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e76f51;
    max-height: 140px;
    flex: 2;
    align-self: stretch;
}

.HomePage .Roles {
    flex: 3;
}

.HomePage > .Roles > div {
    justify-content: center;
}

.HomePage .Developer {
    background-color: #e9c46a;
    flex: 1;
    transition: flex 0.25s linear, font-size 0.5s;
}

.HomePage .Developer.DeveloperHover {
    flex: 4;
    font-size: 32px;
    transition: flex 1s, font-size 0.5s;
}

.HomePage .Photographer {
    background-color: #f4a261;
    flex: 1;
    transition: flex 0.25s linear, font-size 0.5s;
}

.HomePage .Photographer.PhotographerHover {
    flex: 4;
    font-size: 32px;
    transition: flex 1s, font-size 0.5s;
}

.HomePage .Enlarge {
    font-size: 1.2em;
}

.HomePage .Explanation {
    padding: 0 16px 8px;
    text-align: left;
    display: none;
    transition: opacity 0.25s linear;
}

.HomePage .Developer.DeveloperHover .Explanation {
    opacity: 1;
    display: initial;
    transition: opacity 1s 0.3s linear;
}

.HomePage .Photographer.PhotographerHover .Explanation {
    opacity: 1;
    display: initial;
    transition: opacity 1s 0.3s linear;
}

.HomePage .Role {
    flex: 1;
    justify-content: center;
}

.HomePage .Developer .Role > h2 {
    margin-right: 16px;
    align-self: flex-start;
}

.HomePage .Photographer .Role > h2 {
    margin-left: 16px;
    align-self: flex-end;
}

@media screen and (min-width: 800px) {
    .HomePage .Roles {
        flex-direction: row;
    }

    .HomePage .Explanation {
        padding-bottom: 16px;
        padding-top: 2em;
    }

    .HomePage .Role {
        transition: translate 0.5s;
    }

    .HomePage .Developer .Role {
        align-self: flex-end;
        translate: 50% 0;
    }

    .HomePage .Developer.DeveloperHover .Role {
        translate: 0% -50%;
    }

    .HomePage .Developer.PhotographerHover .Role {
        translate: 0%;
    }

    .HomePage .Developer .Role > h2 {
        margin-top: 2em;
    }

    .HomePage .Photographer .Role {
        align-self: flex-start;
        translate: -50% 0;
    }

    .HomePage .Photographer.PhotographerHover .Role {
        translate: 0% calc(-50% + 2em);
    }

    .HomePage .Photographer.DeveloperHover .Role {
        translate: 0%;
    }

    .HomePage .Photographer .Role > h2 {
        margin-bottom: 2em;
    }

    .HomePage .Enlarge {
        font-size: 1.5em;
    }
}

@supports (font-variation-settings: 'wdth' 200) {
    @font-face {
        font-family: Quicksand;
        src: url('../../fonts/Quicksand-VariableFont_wght.ttf');
        font-weight: normal;
        font-style: normal;
    }

    .HomePage .Enlarge {
        font-family: Quicksand;
        font-variation-settings: 'wdth' 400, 'wght' 98;
    }
}
